<template>
  <div class="search-full-container">
      <!-- <v-autocomplete
      label="Who do you wanna meet?"
      class="search"
      placeholder="Baking, Yoga, Presentations, etc.."
      prepend-inner-icon="mdi-magnify"
      solo
      return-object
      hide-details="true"
      v-model="model"
      :cache-items="true"
      :hide-no-data="true"
      :items="items"
      :search-input.sync="search"
      item-text="fullName"
      item-value="objectID"
      :loading="isLoading"
      >

      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Search for <strong>people</strong> or <strong>skills/interests</strong>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:item="data">
          <v-list-item-avatar>
            <img :src="data.item.thumb">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{data.item.fullName}}</v-list-item-title>
            <v-list-item-subtitle v-html="data.item.skills"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      <template v-slot:selection="data">
            {{data.item.fullName}}
        </template>
      </v-autocomplete> -->
      <!-- <v-row no-gutters align="center" justify="space-between">
        <v-col > -->


<!-- :item-text="searchKind === 'people' ? 'fullName' : 'name'" -->
      

      <v-combobox
      ref="search"
      class="search"
      background-color="white"
      :placeholder="searchKind === 'skills' ? 'Baking, Yoga, Presentations, etc..' : 'Search by Name, Skills, Company, etc.'"
      prepend-inner-icon="mdi-magnify"
      solo
      dense flat
      hide-details="true"
      v-model="model"
      cache-items
      return-object

      :items="items"
      :search-input.sync="search"
      :filter="filterItems"
      item-value="objectID"
      item-color="primary"
      :loading="isLoading"
      >

      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Search for <strong>people</strong> or <strong>skills/interests</strong>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:item="{item, on , attrs}">
        <template v-if="searchKind === 'people'">
          <v-list-item-avatar>
            <img :src="item.thumb">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{item.fullName}}</v-list-item-title>
            <v-list-item-subtitle v-html="item.skills.join(', ')"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
          </v-list-item-content>
        </template>
      </template>

      <template v-slot:selection="data">
        {{searchKind === 'people' ? data.item.fullName : data.item.name}}

      </template>
      <!-- <template v-slot:item="data">
        <template v-if="searchKind === 'people'">{{data.item.fullName}}</template>
        <template v-else>{{data.item.name}}</template>
      </template> -->
    </v-combobox>
    <!-- </v-col> -->
    <!-- <v-col cols="auto">
      <v-select
        class="search-kind"
          v-model="searchKind"
          :items="selects"
          label="Solo field"
          solo flat
          hide-details
          :background-color="focused ? 'grey lighten-3' : 'grey lighten-2'"
        ></v-select>
    </v-col>
  </v-row> -->
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
export default {
  name: 'search',
  computed:{

  },
  watch:{
    model (val) {
      if (val != null) {
          if (this.searchKind === 'people') this.$router.push({ name: 'User', params: { userName: val.userName } })
          else this.$router.push({ name: 'Skills', params: { skillId: val.objectID } })
      }
    },
    search (query) {
      let vThis = this;
        // Items have already been loaded
        // if (this.items.length > 0) return
        vThis.isLoading = true

        process.env.NODE_ENV === "development" ? console.log( 'search query', query ) : null;
        vThis.index.search(query, vThis.requestOptions)
        .then(({ hits }) => {
          process.env.NODE_ENV === "development" ? console.log( 'hits', hits ) : null;
          vThis.items = hits;
          vThis.isLoading = false;
        });
      },
      searchKind (val){
        this.setupSearch(val)
      }
  },
  data: () => ({
    model :null,
    search:null,
    items: [],
    isLoading:false,
    index: null,
    client:null,
    requestOptions: null,
    focused:false,
    selects:['skills','people'],
    searchKind: 'people'
  }),
  methods:{
    onFocus(){
      process.env.NODE_ENV === "development" ? console.log( event ) : null;
      this.focused = !this.focused;
    },
    filterItems(item, queryText, itemText) {
      return (item.firstName.toLowerCase().includes(queryText.toLowerCase()) || item.lastName.toLowerCase().includes(queryText.toLowerCase()) || (item.skills.toString()).toLowerCase().includes(queryText.toLowerCase()));
    },
    setupSearch(val){
      this.client = algoliasearch('P1WZL4Y0AZ', 'dfa6463422ca736108276061d8783e71');
      if(val === 'people'){
        this.index = this.client.initIndex('prod_PEOPLE');
        // this.requestOptions = {
        //   headers: { 'X-Algolia-UserToken': 'user123' }
        // }
      }
      else{
        this.index = this.client.initIndex('prod_SKILLS');
      }
    }
  },
  mounted(){
    this.setupSearch('people');
    // let autocompleteInput = this.$refs.search.$refs.input
    //  autocompleteInput.addEventListener('focus', this.onFocus, true)
    // autocompleteInput.addEventListener('blur', this.onFocus, true)
  }
};




</script>


<style lang="scss">
.search-full-container{
  width:100%;
  .search{
    .v-input__append-inner{
      display: none!important;
    }
    .v-input__slot{
      -webkit-box-shadow: 0px 8px 20px rgba(0,0,0,0.06)!important;
      box-shadow: 0px 8px 20px rgba(0,0,0,0.06)!important;
      padding: 16px 16px!important;
      // border-top-right-radius:0!important;
      // border-bottom-right-radius:0!important;
    }
  }
  .search-kind{
    width:100px;

    .v-input__slot{
      padding:13px 4px 13px 8px!important;
      // border-top-left-radius:0!important;
      // border-bottom-left-radius:0!important;
    }
  }
}
</style>
